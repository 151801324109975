import React, {useState} from 'react';
import './Projects.css';
import ImageSlider from './ImageSlider';

type Props = {
    title: string,
    paragraph: string,
    gitLink: string,
    images: any

}

const Projects = ({title, paragraph, gitLink, images}: Props) => {

    return (
    
        <div className='container'>
            <h3 className='project-title'>{title}</h3>
             
            <section className='slider'>
            
            <ImageSlider images={images}/>
            </section>
               

            <p className='description'>{paragraph}</p>   
            <a className='btn' href={gitLink} target="_blank">Check it out</a>
            <p className='info'>Check it out on github and the web!</p>   
            <span/>
        </div>
    )
}

export default Projects;
