import React, { useState } from "react";
import { Link, BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./Navigation.css";

type NavigationProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const Navigation: React.FC = () => {
  const [clicked, setClicked] = useState(false);

  return (
    <nav className="navigation">
      <div className="name">
        <Link to="/home">
          <h3 className="gradient">Thilakshan Baskaran</h3>
        </Link>
      </div>
      <div className="menu-icon" onClick={() => setClicked(!clicked)}>
        <i className={clicked ? `fas fa-times` : `fas fa-bars`}></i>
      </div>

      <div className={clicked ? "nav-menu active" : "nav-menu"}>
        <Link className="middle" onClick={() => setClicked(false)} to="/">
          Home
        </Link>

        <Link
          className="middle"
          onClick={() => setClicked(false)}
          to="/projects"
        >
          Projects
        </Link>

        <Link className="middle" onClick={() => setClicked(false)} to="/about">
          About
        </Link>

        <Link className="middle" onClick={() => setClicked(false)} to="/resume">
          Resume
        </Link>
      </div>
    </nav>
  );
};

export default Navigation;
