import React, { Fragment } from "react";
import "./App.css";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import Navigation from "./Components/Navigation/Navigation";
import Home from "./Pages/HomePage/HomePage";
import About from "./Pages/About/About";
import ProjectsPage from "./Pages/ProjectPage/ProjectsPage";
import Resume from "./Pages/Resume/Resume";

const App = () => {
  return (
    <Router>
      <Fragment>
        <div className="App">
          <Navigation />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} exact />
            <Route path="/projects" component={ProjectsPage} exact />
            <Route path="/resume" component={Resume} exact />
            <Redirect to="/" />
          </Switch>
        </div>
      </Fragment>
    </Router>
  );
};

export default App;
