import React from "react";

import Projects from "../../Components/Projects/Projects";
import {
  stocks,
  SmartHome,
  minesweeperImages,
  gradebookImages,
  flowStateImages,
} from "../../Components/Projects/Images";

import "./ProjectsPage.css";

const ProjectsPage = () => {
  const style = {
    color: "#04619f",
  };

  const mystocks = {
    title: "Stock Sentiment Analysis",
    paragraph: `Web application that displays stocks data and sentiment analysis of article titles from around the web.
      Uses nltk module with vader_lexicon.
      Upon entering ticker, the webpage will display stock data. Most importantly, It will display sentiment analysis graph, which is a natural language processing tehcnique used to determine whether data is positive, negative or neutral. 
      The chart displays the dates the articles were published and the compound score (metric that calcualtes the sum of all lexicon ratings which have been normalized between -1(Most negative) and +1 (Most Positive)`,
    gitLink: `https://github.com/Telakshan/StockSentimentAnalysis`,
  };

  const flowstate = {
    title: "Flow State",
    paragraph:
      "Project management application where teams can chat with each other and collaborate. Implemented a realtime sending and receiving of messages powered by pusher-js. Developd a built in email sending feature to invite more team members. Connected a database where all the messages and channels are stored. https://flowstate-web.netlify.app/",
    gitLink: "https://github.com/Telakshan/FlowState",
  };
  const gradebook = {
    title: "gradebook",
    paragraph: `Social network website for students that also allows students to keep track of their assignments and classes.
    Users are able to create, update, delete, read lists of assignments due.
    Implemented a feature which allows students to post, edit, delete any messages on the news feed. Users are able to like and comment on each others posts.
    Created a secure login/signup system.`,
    gitLink: `https://github.com/Telakshan/gradebook`,
  };

  const minesweeper = {
    title: "Minesweeper website with save capability",
    paragraph: `Web application that allows users to login/play/save the game minesweeper
      Implemented a save system where the player can store the game state in the database`,
    gitLink: `https://github.com/Telakshan/Minesweeper`,
  };

  const foodgram = {
    title: "foodGram",
    paragraph: `Web application using Java spring boot backend and React frontend where users can share pictures of food. Utilizes a RESTful backend server enabling pictures to be stored persistently in an online database.
      Implemented a image storage system that uses AWS S3 bucket service. Displays map of the restaurant using Google Maps API.`,
    gitLink: `https://github.com/Telakshan/foodgram`,
  };

  const smarthome = {
    title: "SmartHome",
    paragraph: `Full-Stack e-commerce site. Uses the ContextAPI for the cart system. Created an extensive REST backend using Spring Boot and Postgres, utilizing the Microservice architecture Architectured a single page application frontend using Reactjs and the MVVM architecture.
    Implemented an extensive customer component with Login/Register and order history. Just for fun!
    `,
    gitLink: "https://github.com/Telakshan/SmartHome",
  };

  return (
    <div className="content">
      <h1 className="title" style={style}>
        Things I've Built
      </h1>

      <Projects
        title={mystocks.title}
        paragraph={mystocks.paragraph}
        gitLink={mystocks.gitLink}
        images={stocks}
      />

      <Projects
        title={smarthome.title}
        paragraph={smarthome.paragraph}
        gitLink={smarthome.gitLink}
        images={SmartHome}
      />

      <Projects
        title={flowstate.title}
        paragraph={flowstate.paragraph}
        gitLink={flowstate.gitLink}
        images={flowStateImages}
      />

      <Projects
        title={gradebook.title}
        paragraph={gradebook.paragraph}
        gitLink={gradebook.gitLink}
        images={gradebookImages}
      />
      <Projects
        title={minesweeper.title}
        paragraph={minesweeper.paragraph}
        gitLink={minesweeper.gitLink}
        images={minesweeperImages}
      />
    </div>
  );
};

export default ProjectsPage;
