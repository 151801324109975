import image1 from "./1.png";
import image2 from "./2.png";
import image3 from "./3.png";
import image4 from "./4.png";

export const stocks = [
  {
    image: "https://i.imgur.com/14gTzMx.png",
  },
];

export const SmartHome = [
  { image: image1 },
  { image: image2 },
  { image: image3 },
  { image: image4 },
];

export const gradebookImages = [
  {
    image: "https://i.imgur.com/V6I1kUm.png",
  },
  {
    image: "https://i.imgur.com/VkwehdH.png",
  },

  {
    image: "https://i.imgur.com/JXnBNLP.png",
  },
];

export const minesweeperImages = [
  {
    image: "https://i.imgur.com/RByNyTm.png",
  },
  {
    image: "https://i.imgur.com/yIEtQCD.png",
  },
  {
    image: "https://i.imgur.com/v7YoytI.png",
  },
  {
    image: "https://i.imgur.com/BP6AREM.png",
  },
  {
    image: "https://i.imgur.com/WhbbINU.png",
  },
  {
    image: "https://i.imgur.com/0QMNRGK.png",
  },
];

export const foodgramImages = [
  {
    image: "https://i.imgur.com/jfrB54Z.png",
  },
  {
    image: "https://i.imgur.com/bGoiyzq.png",
  },
  {
    image: "https://i.imgur.com/uvHVOEq.png",
  },
  {
    image: "https://i.imgur.com/lAy1iym.png",
  },
];

export const flowStateImages = [
  {
    image: "https://i.imgur.com/XyWK9ME.png",
  },
  {
    image: "https://i.imgur.com/TcqMyva.png",
  },
  {
    image: "https://i.imgur.com/WrtGRwO.png",
  },
];
