import React from "react";
import "./Resume.css";

const Resume = () => {

  const style = {
    color: "#04619f",
  };
  return (
    <div className='resume'>
      <h1 className="title" style={style}> Resume </h1>
      <iframe src="https://drive.google.com/file/d/1PFlsJzACuodTcKSYyAOg4tN2zwK-vzLu/preview"  className='embed'></iframe>
    </div>
  );
};

export default Resume;
