import React from "react";

import "./HomePage.css";

const HomePage = () => {

    const style = {
        fontSize: 50
    }
  return (
       <div className='text'>
      <p className="title hello" style={style}>Hello!</p>

      <p className='title' style={style}> I'm Thilakshan</p>

      <p className='subtitle'>I'm a Software engineer based in New York City. <br/> I am interested in the whole full stack spectrum.</p>
    </div>
  );
};

export default HomePage;
